import { useBucket } from '@mc/experiments-react'
import { trackExperimentViewed } from '@mc/track-event'
import { useEffect } from 'react'

// Growthbook experiment: https://app.growthbook.io/features/dais-2987_v1_persona_roleplay
export const ROLEPLAY_EXPERIMENT_FLAG = 'dais-2987_v1_persona_roleplay'

export const useIsRoleplayEnabled = () => {
  const bucket = useBucket<'control' | 'variant_1'>(ROLEPLAY_EXPERIMENT_FLAG)

  useEffect(() => {
    if (bucket)
      trackExperimentViewed(
        ROLEPLAY_EXPERIMENT_FLAG,
        {},
        { trackUserLeap: true },
      )
  }, [bucket])

  return bucket === 'variant_1'
}
