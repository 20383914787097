import { ImageLoaderProps } from 'next/image'

import {
  ARTICLE_IMAGE_BASE,
  CLOUDFLARE_IMAGE_PREFIX,
  STATIC_IMAGE_BASE,
} from './constants'

export const cloudflareLoader = ({
  src,
  width,
  quality = 75,
  format = 'webp',
}: ImageLoaderProps & { format?: string }) => {
  if (src.includes('ctfassets.net')) {
    const regex = /w=([^&]*)/
    const match = regex.exec(src)

    // if the w parameter is found, replace its value with the desired width
    if (match !== null) {
      const currentValue = match[1]
      // Already optimized image src url coming from BE, change manually the width
      return src.replace(`w=${currentValue}`, `w=${width}`)
    }

    return `${src}?w=${width}`
  }

  const params = []
  params.push([`width=${width}`])
  params.push(`quality=${quality}`)
  params.push(`format=${format}`)

  if (src.includes(STATIC_IMAGE_BASE) || src.includes(ARTICLE_IMAGE_BASE)) {
    return `${CLOUDFLARE_IMAGE_PREFIX}/${params.join(',')}/${src}`
  }

  return `${src}?${params.join('&')}`
}
