import React from 'react'
import NextImage, { type ImageProps as NextImageProps } from 'next/image'

import { cloudflareLoader } from '@mc/image-cloudflare'

export type ImageProps = Omit<NextImageProps, 'loader'> & {
  src: string | undefined
}

const Image = ({ src, ...props }: ImageProps) => {
  if (!src) return <></>

  return <NextImage {...props} loader={cloudflareLoader} src={src} />
}

export default Image
