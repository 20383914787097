import { paths } from './paths'

export const ROLEPLAY_NAV_TABS = {
  COACHES: 'coaches',
  ROLEPLAY: 'role-play',
}

export const getRoleplayActiveTab = (pathname: string) => {
  if (pathname.includes(paths.homepageRoleplay)) {
    return ROLEPLAY_NAV_TABS.ROLEPLAY
  }
  return ROLEPLAY_NAV_TABS.COACHES
}
