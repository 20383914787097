import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import cn from 'classnames'

import { useTranslation } from '@mc/i18n'

import { paths } from '../../utils/paths'
import { getRoleplayActiveTab, ROLEPLAY_NAV_TABS } from '../../utils/roleplay'

export const RoleplayTabs = ({ className = '' }: { className?: string }) => {
  const { pathname } = useRouter()
  const { t } = useTranslation('@mc/persona')
  const roleplayActiveTab = getRoleplayActiveTab(pathname)

  return (
    <div
      className={`d-flex flex-gap-8 align-items-center justify-content-center flex-1 ${className}`}
    >
      <Link
        className={cn('mc-text--bold', {
          'mc-opacity--muted': roleplayActiveTab !== ROLEPLAY_NAV_TABS.COACHES,
        })}
        href={paths.homepageCoaches}
      >
        {t('common.coaches')}
      </Link>
      <Link
        className={cn('mc-text--bold', {
          'mc-opacity--muted': roleplayActiveTab !== ROLEPLAY_NAV_TABS.ROLEPLAY,
        })}
        href={paths.homepageRoleplay}
      >
        {t('common.roleplay')}
      </Link>
    </div>
  )
}
